import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Jimi's PGP Public Key`}</h1>
    <p>{`If you don't know what this is, ask me sometime.`}</p>
    <p><inlineCode parentName="p">{`B466 D23E 6E2E 22DD F3E2  7D6F 9886 0987 5701 C888`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFoOTREBEAClWR/JIUvzwZLJo0jbKxe+tAY+TNX148rzhIwFoRecHdP68ErN
dZfkBLrQaMlgF/eA1p7um7cDPMY3p558QYiz8hW6BAS5mUsvFkP4ajEVhk6Z12eu
I8ALtcpFoEQnms5LlJuqJ+RJ4zY5520el3+kQh/VBvQ4o0DOgkFiSHSBaS/Phh1C
WgUPUQNf5wDsTsXlIfaiWB8q/yVyTptXZnzx8wLNquiNQYv1899jQE4smEGXaBUM
Rwys7S9EbOeg3n6VpHYPI/CbDbceyiTdqHtXbIdd2CwwBORQrS8MAGAWbpxxsNYk
JZ3lp2V2RgpUtU0t2ETkHhPm613pg7FGlppICWSI+ctC+91wJT81qbIIoINzIQNT
xRQpR6u1lEq8Pf4vW6K2qH9sUpOQNpNN7awlpFxMXuu98BGA2ujck18WyuO011ww
d+IOazD+rwBohVbSHALuedEauXfDU4tobDD5x3jVK+GtclOjcZo/ddEqUNwjzZwu
bByo32yVh2kqhw9hBKtxecVjAv9S9JnoeWaRX89NO+s7dbr+ZOiDseZfahZdYGAD
bEWoAEP4WbUWuLT4mt4jmTYdojsPHo6Pg0Y2XnjwfCIC8kiI4XGWph84l0oRcyfw
MBFn5vp/C0tzh9Apfx3hQGa+CvOpxBPw03NtpzaUS0oKPZGWjNI/xYMYJwARAQAB
tCBKYW1lcyBTbW9vdCBJViA8amltaUBqc2ZvdXIuY29tPokCVAQTAQgAPhYhBLRm
0j5uLiLd8+J9b5iGCYdXAciIBQJaDk0RAhsDBQkFo5qABQsJCAcCBhUICQoLAgQW
AgMBAh4BAheAAAoJEJiGCYdXAciIj/AP/ilbWzA1cppeqrdquUvSxo7IjIPotIFk
pJgNhzSvaaE9Me4mtZ0v7y34SAnD8vTqhrPxv8IUmBuFD8Df54nlx+mt2NqrKl+i
kaEIyp4yavl8Sbl0VGmQQHxjuYdr8lDdJPxqX1PBg4A6oS2sSFoiNsEpPab7heap
mK+iUO+rdq0x8LvYQMLjPUcEiwZLNqr/WI4y48QT/8XGNWTiIirijOU1KyxIfKJd
9B+Yd+jKElchrm7DrmYmC/cKZUxjB4xcrtd/sZTTMaghSFrtfKwoQHvFjRRfOSNB
w6jdX+2flWY7aE5NQ0paSOGtn5mtuN+iwIAmrpy6xA8HRSZ1FNoeeCnXhtsohg88
dfyLtT2wVOQ16zGlu9In3UcHd6AjW5gQoJiX8DttnTTKO8OnhPGGStm78JH9wsTq
Qsx0RwUroK+VEsMkU9/6RY+tkQ6mPu3jKvMpJIFpLHI+UVsUkURDsIG+ZhOE+nVx
ED4CQqx7+0qdgP+pDXHDoaDzmEvJt1r+ItBKC8EKj1riVEWqimHMgQ3vWGgn5hPC
MnAM2gVGBIjeHRvaMBkXyRhQQN5Gew3ltqBCgJ91yNm6jOAq4B5pBaDjFZOFWq5m
4b0ZYYDUeF1CIHo/Uz4FSze+TpvC8hiNneUIlSj/d2k47bWqGzSvZVB7x5jOByhH
eJbpuTZzFX4LuQINBFoOTREBEAC722njtC0iwdsHFRL0s9ZK5KdzJoMU6qN2vNJL
uWdJRvC+otvBT7Gu9R0f26NM0iYHe3guUGNw3SdS8vIPnGdZ6n1k8UP6MmkG4oFq
eZDiePcWnfrXd7mKTFopzTP19LrvsvHrxTwqXBFgCDZbTvugPxCcKlLMsAt/hL/7
SSsTTGwaW5kx6p59aa0b+gNHMg+DFVKEl0umZGmKU2GH5Qve7VI4dK0NQRtfzGw7
l9f+v21B8Fe9yV/WPIcgrvu+3qzkLXufxdoYjZZoH4U5X9hiDpoSBiw9nfJOiMDY
sjvARP6RGW5lvJy4VK7HR8FtGO6TOjd+UKKfnC++HMew+U8ydB/VfSlK9gWr/VW9
fE0wMjtjSa57VFfFMvOlQUpCTJFAxLk+mTwtAN1H3ZskOWRKXJgd3VzDuP5O2aav
ZL+RR4vhcfgaL1t0VJ0/h00MuuZFNUN78SWwdwbm12iflH/gWPdAIIvQlIBZ3iqp
ZiR+UJ8i2fKXNsGbN2G2Luo8VaEP+ulzb0238NnCdEALW7jQrOaYspJSQGZRNK/C
IvbXIaEtNzAmFTOnl7R5mKqpsuWueQovg95buL8yMX6kkFNmZE92TpGtvJtyjGpF
GIZ0TvdPXbaknpwhTNKfONUGpWwreym3K7pApn7P3dZUdRSgLd8RRulkV4vQiAYJ
ERcc9QARAQABiQI8BBgBCAAmFiEEtGbSPm4uIt3z4n1vmIYJh1cByIgFAloOTREC
GyAFCQWjmoAACgkQmIYJh1cByIjAhQ/8DVHFRkWV0iz3MXUi3lTHxMWKjDRSu1JC
z9WJ/nMWxlP+yhtaifGDeXPB+572vgP8wSsSr1oVSpmyFGWCsz1wteqvRHcloCzi
HbSlSx7/0LiMS68okLNVOL8YkKIYBcvri8Rw7qPQSBIy5eh0uJyx83ppAJDVydOz
IG018J8Xtj2riTpVHWEaSxmDuR+NrLZ5QvcwAKemivOpEZ856AJI7dy25Ty+4zw0
TEWfbPFVlqhVB5zWWyImGbj9Ny61kRDpF4jw7hF2KTLilZumbe9eswfmUNrmMGBU
uVPVWFYe4Wzx5XXGqzq3jDum48l1t6LFZ43HPpE3K8BKAiDUOepqhXhVbKJw9bQ8
VwhrpASn3JbU/s67/QMV4lQMfVXKYwb5heBs68lqTrkOQ9f0gN3B+vCQQHHFMKGf
j02EAQlpWuZmRge4noAlPTTIKR3617QZJNEhCqCxs48qM6v3RTa/1YTkdWpe1TIK
HV7Gi+Oj+Oj5O146yNuP3ujXRmJl0L+v+Hm4Jw3sSeLi589wkiyyEXbCqpIq6X14
EedHW7bjaQgtsrHtS1pmpk6ZhJfiDlMOsWcYpw8Pe6hOl9U4rH7qqGBtcR9g2k+U
RYovXNtzT6sDfDELGmP7JA/e6MmfcvOMkOQUU8wClKW/jeNWC9iB4bCKgLb8Qqzl
l+z0GJ73whK5Ag0EWg5NEQEQAK83J14n2e2l8JZa0LdU2F9dhUOM2dvuUH1B9G5N
2c8HUHSLauM60sbVi8iJ3PmdpC+4yJBStiLpXK5+F4OQCc+IrBd8Xc8Am/RH9msu
n1ZiKDWUL31TaucFO1IGvg4KQEfNDp/miyb0HcMIal2UFbVvtkgKK1xv4sbeT0Xc
kkNmAh8Igb5mN4vsTWer/GiBp29yGBvhnOk0npWomoX1fVYjyy0L19npdJp7lqPn
Rv8HTjnOrP0iUuQ0t/HWEqReMZS4PqoUWejAROdXpUzk6WeUxTRS2U7p4Hf7v2WP
KIH/5Xi8LTyIPzubU5wPLUAY8pwiUAO2scFdSSxsI2/fHfqgSsRqTiT38g+LNdoC
+qgRl5SnYUzitsClWdSxRGgaWhQyqSy3dOQzTta0nhpy+gVIwiJ2Nasgm0Lm8r2b
IGNzkKL0zGwkswEigjv1PCbuNOT9KoT2snv+GQev7MJaKcDs+aJj0k1dTUSl1dZE
A2ngkXGjpnEhVnsMCThNyWs69kMK7tj8J+i5A5a08jgRQcvRNIbF+CW7HVda1j3D
a4NI8ziKVc7+0+GHj0G4ULQkAY8VFyuZPLAIS16gkyfDtHmY9BLi1YC1BQ5EVLKP
CZd/k+iJGqca2ddWtNokJpKk9g9k2b+CvrrUuJiERA2TuMPKgb/1WQmYrjJZQdN3
QHCnABEBAAGJAjwEGAEIACYWIQS0ZtI+bi4i3fPifW+YhgmHVwHIiAUCWg5NEQIb
DAUJBaOagAAKCRCYhgmHVwHIiE3DD/4/TCsEWs1GrNcUPsIyFpps4TOkrUPaTo68
cjo2sYegJ6rS4hccwsiJ5es7x/wOKNi+tzzzl51CDncGrG9aTS/nlrTJODs6/AXh
TgOS0StdeBUfZarlOmSTNX5Xt2WtnBWm19kJgL39Ge4fMStfa6mqeglGhpPqVDfR
8noD/CDF3Hlyg085EzumNxImfpkQrIzR3IzftA0rtJe32qCXN/kfVHgltfT0As61
IvcWAKcGMLRK01X2IkuE1DoBpeCQ8AV/Ld9bVZANaFfnQozNjQFkS1jaD8bkO+8H
1EujLzSwdMZgKMGISamozEcqo/loFaTCchPAjWkJ/uOQYjMCX3ZOByGyeng8Tv56
quvMbAAyg4333hFGx0D2QasrJkNncPcWbS4NAnnabnzb9eWKl4eJGoG/gyHBDuNF
+YJWmRKzVWeyeUAqu1/3KFuvakXS/LLTVvrxoTaAgZDZQRMNQ9eSwx75N17rSbkf
8JRkPl+VFYd9ej/ga7uogBGeqLmQdov5MNcnoiZh28EwOp1w7nVps2yL4l9mSRrf
cOK2eFQztTOMFEL5GUaftlyEe0gM7Jnc2wGN9wC5UXqIt1aMyi37jk4F58PwRmkn
f5q5KtmiMHyFtaWVrbWC9MJm3h4ytg/zMzh8/P+zqRzJ7CyvM0hm4wS++wb79QkD
WlpcYwHkGg==
=84e8
-----END PGP PUBLIC KEY BLOCK-----
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      