import React from "react"
import Layout from "./Layout"
import SEO from "./Seo"
import RenderImage from "./elements/RenderImage"
import styles from "./postlayout.module.css"

export default props => {
  const {
    pageContext: { frontmatter },
    children,
  } = props
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className={styles.post}>
        <RenderImage frontmatter={frontmatter} />
        <div className="post">{children}</div>
      </div>
    </Layout>
  )
}
