import React from "react"
import Image from "../Image"

export default ({ frontmatter }) => {
  if (!frontmatter.image) {
    return null
  }

  return <Image src={frontmatter.image} />
}
